import React from 'react';
import { Box } from '@material-ui/core';

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ paddingBottom: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default TabPanel;
